import './MerchantReview.scss';
import React from 'react';
import { FlightButton } from '@flybits/design-system';
import { MerchantFormValues } from 'pages/MerchantPortal/merchant-portal.types';

const MERCHANT_REVIEW = 'merchant-review';
const MERCHANT_REVIEW_CLASSES = {
  HEADER: `${MERCHANT_REVIEW}__header`,
  HEADER_NUMBER: `${MERCHANT_REVIEW}__header__number`,
  HEADER_TITLE: `${MERCHANT_REVIEW}__header__title`,
  SECTIONS: `${MERCHANT_REVIEW}__sections`,
  FOOTER: `${MERCHANT_REVIEW}__footer`,
};

const SECTION = 'merchant-review-section';
const SECTION_CLASSES = {
  TITLE: `${SECTION}__title`,
  FIELDS: `${SECTION}__fields`,
  FIELD: `${SECTION}__fields__field`,
  FIELD_KEY: `${SECTION}__fields__field__key`,
  FIELD_VALUE: `${SECTION}__fields__field__value`,
  FIELD_VALUE_IMAGE: `${SECTION}__fields__field__value--image`,
};

const MERCHANT_FIELDS = {
  name: 'Merchant Name',
  id: 'Merchant ID',
  category: 'Category',
  phone: 'Phone number',
  address: 'Address',
  logo: 'Merchant Logo',
};

const MERCHANT_CONTACT_PERSON_FIELDS = {
  name: 'Contact person',
  phone: 'Phone number',
  email: 'Email',
};

interface SectionFieldProps extends React.ComponentProps<'div'> {
  name: string;
  fields: { [key: string]: string };
  values: { [key: string]: string | JSX.Element | null };
}

const Section: React.FC<SectionFieldProps> = ({ name, fields, values }) => (
  <section className={SECTION}>
    <h4 className={SECTION_CLASSES.TITLE}>{name}</h4>
    <div className={SECTION_CLASSES.FIELDS}>
      {Object.keys(fields).map((key) => (
        <div className={SECTION_CLASSES.FIELD} key={key}>
          <div className={SECTION_CLASSES.FIELD_KEY}>{fields[key as keyof typeof fields]}</div>
          <div className={`${SECTION_CLASSES.FIELD_VALUE} ${key === 'logo' ? SECTION_CLASSES.FIELD_VALUE_IMAGE : ''}`}>
            {values[key as keyof typeof values]}
          </div>
        </div>
      ))}
    </div>
  </section>
);

interface MerchantReviewProps extends React.ComponentProps<'div'> {
  data: MerchantFormValues;
  onBackClick: () => void;
  onSubmit: () => void;
}
const MerchantReview: React.FC<MerchantReviewProps> = ({ onBackClick, onSubmit, data }) => {
  const { name, id, category, logo, phone, address, city, state, zip, contact } = data;
  return (
    <div className={MERCHANT_REVIEW}>
      <header className={MERCHANT_REVIEW_CLASSES.HEADER}>
        <div className={MERCHANT_REVIEW_CLASSES.HEADER_NUMBER}>1</div>
        <h2 className={MERCHANT_REVIEW_CLASSES.HEADER_TITLE}>Create Merchant Profile</h2>
      </header>
      <div className={MERCHANT_REVIEW_CLASSES.SECTIONS}>
        <Section
          name="About the Merchant"
          fields={MERCHANT_FIELDS}
          values={{
            name,
            id,
            category,
            phone,
            address: `${[address, city, state, zip].filter((v) => v).join(', ')}`,
            logo,
          }}
        />
        <Section
          name="Merchant Contact Person"
          fields={MERCHANT_CONTACT_PERSON_FIELDS}
          values={{
            name: contact.name,
            phone: contact.phone,
            email: contact.email,
          }}
        />
      </div>
      <footer className={MERCHANT_REVIEW_CLASSES.FOOTER}>
        <FlightButton label="Back" theme="secondary" onClick={onBackClick} />
        <FlightButton label="Confirm & Submit" onClick={onSubmit} />
      </footer>
    </div>
  );
};

export default MerchantReview;
