import React, { useEffect } from 'react';

import useExitPrompt from 'hooks/useExitPrompt';
import { useHistory } from 'react-router-dom';
import useConfirm, { ConfirmationDialogProps } from 'hooks/useConfirm';
import IconDiscardChanges from 'components/Shared/Icons/IconDiscardChanges';
import { ConfirmationModalTypes } from '../shared.types';

type UnsavedPromptProps = {
  when: boolean;
  dialogProps?: Partial<ConfirmationDialogProps>;
  unblockPaths?: Array<string>;
};

export default function UnsavedPrompt(props: UnsavedPromptProps) {
  const {
    when,
    dialogProps: { theme, title, icon, description, primaryAction, secondaryAction } = {},
    unblockPaths,
  } = props;
  const history = useHistory();
  const [, setShowExitPrompt] = useExitPrompt(when);
  const confirmationDialogProps: ConfirmationDialogProps = {
    theme: theme || ConfirmationModalTypes.DISCARD,
    icon: icon || <IconDiscardChanges />,
    title: title || 'You are leaving this page',
    description: description || 'This will undo all changes that have been made since the last time you published',
    primaryAction: primaryAction || {
      value: 'Discard changes',
    },
    secondaryAction: secondaryAction || {
      value: 'Cancel',
    },
  };
  const [ConfirmationDialog, confirmLeave] = useConfirm(confirmationDialogProps);

  useEffect(() => {
    const unblock = history.block(({ pathname }) => {
      const allowNavigation = unblockPaths?.every((path) => pathname.includes(path.toLowerCase()));

      if (when && !allowNavigation) {
        (confirmLeave() as Promise<boolean>).then((res) => {
          if (res) {
            // Unblock the navigation.
            unblock();
            // Retry navigation
            history.push(pathname);
          } else {
            return false;
          }
        });
        return false;
      }
    });
    return () => {
      setShowExitPrompt(false);
      unblock();
    };
  }, [confirmLeave, history, setShowExitPrompt, when, unblockPaths]);

  return <>{ConfirmationDialog()}</>;
}
