import '../ZoneModuleSetup/ZoneModuleTabs/ZoneModuleTabs';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import ZoneModuleTabs from '../ZoneModuleSetup/ZoneModuleTabs/ZoneModuleTabs';
import ModuleInfo from '../ModuleInfo/ModuleInfo';
import ModuleLayout from '../ModuleLayout/ModuleLayout';
import { ZM_DETAILS_CLASSES } from '../ZoneModuleSetup/constants';
import { ZoneModuleTabKeys, MODULE_LAYOUTS } from '../types';
import { ModuleDetailsTab } from 'pages/ZonesV2/Zone';
import { TSlidingSidePanelProps } from 'components/ExperienceCanvas/types';
import MemoizedSlidingSidePanel from 'components/ExperienceCanvas/SlidingSidePanel/SlidingSidePanel';
import PrioritizationContext from 'pages/ZonesV2/PrioritizationContext/PrioritizationContext';
import { Module } from 'pages/Zones/types';
import ModuleCreateDetailsIcon from 'pages/ZonesV2/Icons/ModuleCreateDetailsIcon';
import ModuleEditDetailsIcon from 'pages/ZonesV2/Icons/ModuleEditDetailsIcon';
import { validateModuleInfo, validateModuleLayout } from '../zone.validators';
import { TTimeoutId, debounce } from 'helpers/common.helper';
import ConfirmationModal from '../../../Shared/ConfirmationModal/ConfirmationModal';
import IconDiscardChanges from 'components/Shared/Icons/IconDiscardChanges';
import { ConfirmationModalTypes } from 'components/Shared/shared.types';

const tabs: ModuleDetailsTab[] = [
  {
    key: ZoneModuleTabKeys.INFO,
    name: 'Module Info',
    title: `Now let’s set up your module info`,
  },
  {
    key: ZoneModuleTabKeys.LAYOUT,
    name: 'Module Layout',
    title: `Now let’s set up your module layout`,
  },
];

/**
 * The slide out page for module details, when created or modified.
 * Should contain the logic for navigating between the steps,
 * as well as swapping what's in the tab content children (content area)
 */
const ModuleDetailsSlideout: React.FC<React.ComponentProps<'div'>> = () => {
  const {
    addModule,
    updateModule,
    removeModule,
    selectedModule,
    selectedZone: { id: selectedZoneId },
    setSelectedModule,
    showModuleDetailsSlideout: show,
    setShowModuleDetailsSlideout: setShow,
    isCustomLayout,
  } = useContext(PrioritizationContext);

  const moduleLayout = (selectedModule?.layout as MODULE_LAYOUTS) || MODULE_LAYOUTS.VERTICAL;

  const [activeTabIdx, setActiveTabIdx] = useState(0);
  const [primaryActionDisabled, setPrimaryActionDisabled] = useState(true);
  const debounceTimeoutId = useRef<TTimeoutId>({ timeoutId: undefined });

  const [isRemoveZoneConfirmationModalOpen, setRemoveZoneConfirmationModalOpen] = useState(false);
  const [removeSelectedModule, setRemoveSelectedModule] = useState(false);

  const { primaryButtonInfo, secondaryButtonInfo } = useMemo(() => {
    const primaryButtonInfo = {
      primaryActionText: '',
      primaryActionHandler: () => {
        return;
      },
      primaryActionDisabled,
    };
    const secondaryButtonInfo = {
      secondaryActionText: '',
      secondaryActionHandler: () => {
        return;
      },
    };

    if (tabs[activeTabIdx].key === ZoneModuleTabKeys.INFO) {
      if (!isCustomLayout) {
        primaryButtonInfo.primaryActionText = 'Apply';
        primaryButtonInfo.primaryActionHandler = () => {
          if (selectedModule?.id) {
            selectedModule.layoutHTML = '';
            selectedModule.styleCSS = '';
            updateModule(selectedZoneId as string, selectedModule as Module);
          } else {
            addModule(selectedZoneId as string, selectedModule as Module);
          }
          setShow(false);
          setSelectedModule({});
        };
      } else {
        primaryButtonInfo.primaryActionText = 'Next Step';
        primaryButtonInfo.primaryActionHandler = () => setActiveTabIdx(1);
      }

      secondaryButtonInfo.secondaryActionText = 'Cancel';
      secondaryButtonInfo.secondaryActionHandler = () => {
        setShow(false);
        setSelectedModule({});
      };
    }

    if (tabs[activeTabIdx].key === ZoneModuleTabKeys.LAYOUT) {
      primaryButtonInfo.primaryActionText = 'Apply';
      primaryButtonInfo.primaryActionHandler = () => {
        if (selectedModule?.id) {
          updateModule(selectedZoneId as string, selectedModule as Module);
        } else {
          addModule(selectedZoneId as string, selectedModule as Module);
        }
        setShow(false);
        setSelectedModule({});
      };

      secondaryButtonInfo.secondaryActionText = 'Previous Step';
      secondaryButtonInfo.secondaryActionHandler = () => setActiveTabIdx(0);
    }

    return { primaryButtonInfo, secondaryButtonInfo };
  }, [
    activeTabIdx,
    isCustomLayout,
    selectedZoneId,
    selectedModule,
    addModule,
    updateModule,
    setSelectedModule,
    primaryActionDisabled,
    setShow,
  ]);

  const openConfirmationBox = useCallback(() => {
    setRemoveZoneConfirmationModalOpen((prev) => !prev);
  }, [setRemoveZoneConfirmationModalOpen]);

  const moduleDetailsSlideoutProps = useMemo<TSlidingSidePanelProps>(
    () => ({
      show: show,
      headerInfo: {
        mainTitle: '',
        goBackTitle: selectedModule?.id ? 'Editing Module' : 'Create New Module',
        goBackActionHandler: () => setShow(false),
        goBackSubTitle: selectedModule?.id ? 'Edit your module details' : 'Set up your module details',
        goBackIcon: selectedModule?.id ? <ModuleEditDetailsIcon /> : <ModuleCreateDetailsIcon />,
      },
      footerInfo: {
        ...primaryButtonInfo,
        ...secondaryButtonInfo,
      },
      onSlideOutComplete: () => {
        removeSelectedModule &&
          selectedZoneId &&
          selectedModule &&
          removeModule(selectedZoneId, selectedModule as Module);
        setRemoveSelectedModule(false);
      },
    }),
    [
      show,
      setShow,
      primaryButtonInfo,
      secondaryButtonInfo,
      selectedModule,
      selectedZoneId,
      removeSelectedModule,
      removeModule,
      setRemoveSelectedModule,
    ],
  );

  // Render `ModuleInfo` tab by default when slideout opens
  useEffect(() => {
    if (show) {
      setActiveTabIdx(0);
    }
  }, [show, setActiveTabIdx]);

  // Check if primary action buttons in slideout footer should be disabled
  useEffect(() => {
    const checkDisabled = async () => {
      if (activeTabIdx === 0) {
        const {
          name: mName,
          height,
          layout,
        } = validateModuleInfo(
          // Override layout option validation if users select `Custom` layout
          moduleLayout || isCustomLayout,
          selectedModule?.name,
          selectedModule?.height,
        );
        setPrimaryActionDisabled(!!(mName || height || layout));
      } else if (activeTabIdx === 1) {
        const { layoutHTML } = await debounce(
          validateModuleLayout,
          500,
          debounceTimeoutId.current,
        )(selectedModule?.layoutHTML);
        setPrimaryActionDisabled(!!layoutHTML);
      }
    };
    setPrimaryActionDisabled(true);
    checkDisabled();
  }, [activeTabIdx, selectedModule, moduleLayout, isCustomLayout]);

  return (
    <>
      <MemoizedSlidingSidePanel {...moduleDetailsSlideoutProps}>
        <div className={`zone__slidingSidePanel`}>
          <ZoneModuleTabs tabs={tabs} activeIdx={activeTabIdx}>
            <div className={ZM_DETAILS_CLASSES.CONTAINER}>
              <div className={ZM_DETAILS_CLASSES.HEADER}>{tabs[activeTabIdx].title}</div>
              {}
              {tabs[activeTabIdx].key === ZoneModuleTabKeys.INFO &&
                (selectedModule?.id ? <ModuleInfo onRemove={openConfirmationBox} /> : <ModuleInfo />)}
              {tabs[activeTabIdx].key === ZoneModuleTabKeys.LAYOUT && <ModuleLayout />}
            </div>
          </ZoneModuleTabs>
        </div>
      </MemoizedSlidingSidePanel>
      <ConfirmationModal
        isOpen={isRemoveZoneConfirmationModalOpen}
        closeModal={() => setRemoveZoneConfirmationModalOpen(false)}
        theme={ConfirmationModalTypes.DISCARD}
        icon={<IconDiscardChanges />}
        title="Remove module?"
        description="Removing this module will also remove any modules within it. Once you remove the module you can’t undo it."
        primaryAction={{
          value: 'Yes, continue',
          onClickHandler: () => {
            setShow(false);
            setRemoveZoneConfirmationModalOpen(false);
            setRemoveSelectedModule(true);
          },
        }}
        secondaryAction={{ value: 'Cancel', onClickHandler: () => setRemoveZoneConfirmationModalOpen(false) }}
      />
    </>
  );
};

export default ModuleDetailsSlideout;
