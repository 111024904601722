// Currency formatter
export const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
});
export function formatCurrency(
  e: React.ChangeEvent<HTMLInputElement>,
  setFieldValue: (field: string, value: number | string) => void,
  field: string,
) {
  const inputField = e.target;
  const number = parseInt(inputField.value.replace(/[^0-9]/g, ''), 10);
  setFieldValue(field, !isNaN(number) ? currencyFormatter.format(number / 100) + '' : '');
}

export const dateToEpoch = (date: Date) => {
  if (date == null) date = new Date(0);
  return (date.getTime() - date.getMilliseconds()) / 1000;
};

export function validateInput(e: React.KeyboardEvent<HTMLInputElement>) {
  // Allow only number keys, control keys, and decimal points
  if (!/[0-9]|Backspace|ArrowLeft|ArrowRight|Delete|Tab|Enter/.test(e.key)) {
    e.preventDefault(); // Prevent non-numeric and non-control keys
  }
}
