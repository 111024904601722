// maybe we should merge the constants files at some point :/
import { PushUniversalAction, PushFieldMetadata } from 'components/ExperienceCanvas/types';
import CircleCheckIcon from 'pages/ExperienceCanvas/assets/icons/CircleCheckIcon';
import ErrorIcon from 'pages/ExperienceCanvas/assets/icons/ErrorIcon';
import LoadingIcon from 'components/Shared/LoadingIcon/LoadingIcon';
import PendingIcon from 'pages/ExperienceCanvas/assets/icons/PendingIcon';
import UploadingIcon from 'pages/ExperienceCanvas/assets/icons/UploadingIcon';
import { PUSH_PAYLOAD_TYPES } from 'pages/ExperienceCanvas/types';
import { CONTENT_ACTION_TYPES } from 'store/content/content.type';

export const PushFormClassPrefix = 'push-form';

export const PERIODS = [
  { key: 'once', name: 'Once' },
  { key: 'second', name: 'Seconds' },
  { key: 'minute', name: 'Minutes' },
  { key: 'hour', name: 'Hours' },
  { key: 'day', name: 'Days' },
  { key: 'week', name: 'Weeks' },
  { key: 'month', name: 'Months' },
  { key: 'year', name: 'Years' },
];

// maybe we can merge the periods later
export const EVENT_PERIODS = [
  { key: 'now', name: 'Immediately' },
  { key: 'minutes', name: 'Minutes' },
  { key: 'hours', name: 'Hours' },
  { key: 'days', name: 'Days' },
  { key: 'weeks', name: 'Weeks' },
  { key: 'months', name: 'Months' },
  { key: 'years', name: 'Years' },
];

export const FORM_WIDTHS = {
  SMALL: '40px',
  MEDIUM: '85px',
  LARGE: '140px',
};

export const FILE_STATUSES = {
  PROCESSING: {
    bgColor: '#daf6ff', // teal-50
    fgColor: '#185987', // teal-700
    title: 'In progress',
    text: 'Processing...',
    oneWordText: 'Processing...',
    Icon: LoadingIcon,
    priority: 1,
  },
  PROCESSING_INTERRUPTED: {
    bgColor: '#fdf9d4', // yellow-50
    fgColor: '#ffb005', // yellow-500
    title: 'In progress',
    text: 'Processing...',
    oneWordText: 'Processing...',
    Icon: LoadingIcon,
    priority: 1,
  },
  PROCESSING_FAILED: {
    bgColor: '#ffe1de', // red-50
    fgColor: '#dc3616', // red-500
    title: 'Failed to process',
    text: 'Failed to process',
    oneWordText: 'Failed',
    Icon: ErrorIcon,
    priority: 2,
  },
  UPLOADING_FAILED: {
    bgColor: '#ffe1de', // red-50
    fgColor: '#dc3616', // red-500
    title: 'Failed to upload',
    text: 'Failed to upload',
    oneWordText: 'Failed',
    Icon: ErrorIcon,
    priority: 3,
  },
  UPLOADED: {
    bgColor: '#f2f3f5', // neutral-color-50
    fgColor: '#505a72', // neutral-color-700
    title: 'Ready to process',
    text: 'Pending to process',
    oneWordText: 'Pending',
    Icon: PendingIcon,
    priority: 4,
  },
  UPLOADING: {
    bgColor: '#e9e7ff', // purple-50
    fgColor: '#3f1887', // purple-700
    title: 'Uploading',
    text: 'Uploading...',
    oneWordText: 'Uploading...',
    Icon: UploadingIcon,
    priority: 5,
  },
  PROCESSING_SUCCESS: {
    bgColor: '#cdf8ee', // green-50
    fgColor: '#006117', // green-700
    title: 'Completed',
    text: 'Processed',
    oneWordText: 'Processed',
    Icon: CircleCheckIcon,
    priority: 6,
  },
  UNKNOWN: {
    bgColor: '#ffe1de', // red-50
    fgColor: '#dc3616', // red-500
    title: 'Unknown status',
    text: 'Unknown status',
    oneWordText: 'Unknown',
    Icon: ErrorIcon,
    priority: 7,
  },
};

/////////////////////////// PUSH CONSTANTS /////////////////////////////////////
export const PushUniversalActionTypes: PushUniversalAction[] = [
  {
    name: 'Email',
    minVersion: 4.4,
    prefix: 'mail:',
    basicValidation: 'email',
    fields: [{ key: 'sendTo', name: "Receiver's Email" }],
  },
  {
    name: 'Phone Number',
    minVersion: 4.4,
    prefix: 'phone:',
    basicValidation: 'phone',
    fields: [{ key: 'phoneNumber', name: 'Phone Number' }],
  },
  {
    name: 'Concierge',
    minVersion: 4.4,
    prefix: 'concierge:',
    basicValidation: '',
    fields: [{ key: '', name: 'Optional fields' }],
  },
  {
    name: 'External Application',
    minVersion: 4.4,
    prefix: 'universalLink:',
    basicValidation: 'url',
    fields: [
      { key: 'ios', name: 'iOS' },
      { key: 'android', name: 'Android' },
    ],
  },
  {
    name: 'Web Link (Opens in-app browser)',
    minVersion: 4.4,
    prefix: 'web:',
    basicValidation: 'url',
    fields: [{ key: 'url', name: 'URL' }],
  },
  {
    name: 'Web Link (Opens external browser)',
    minVersion: 4.4,
    prefix: 'browser:',
    basicValidation: 'url',
    fields: [{ key: 'url', name: 'URL' }],
  },
  {
    name: 'Application Deep Link',
    minVersion: 4.4,
    prefix: 'app:',
    basicValidation: '',
    fields: [{ key: 'name', name: 'Deep Link Parameters' }],
  },
  {
    name: 'Details',
    minVersion: 5,
    prefix: 'details:',
    basicValidation: 'string',
    fields: [
      { key: 'contentId', name: 'Content Identifier' },
      { key: 'title', name: 'Title' },
    ],
  },
];

export const PushFormBodyFields: PushFieldMetadata = {
  alert: {
    displayName: 'message',
    path: 'body[0].payload.alert',
    isLocalized: true,
    basicValidation: 'text',
  },
  title: {
    path: 'body[0].payload.title',
    isLocalized: true,
    basicValidation: 'string',
  },
  pushPayloadTypeId: {
    path: 'body[0].payload.data.pushPayloadTypeId',
    isLocalized: false,
    basicValidation: '',
  },
};

export const PushFormDeliveryFields: PushFieldMetadata = {
  max: {
    path: 'options.max',
    isLocalized: false,
    basicValidation: 'number',
  },
  cooldown: {
    path: 'options.cooldown',
    isLocalized: false,
    basicValidation: 'number',
  },
  delay: {
    path: 'options.delay',
    isLocalized: false,
    basicValidation: 'number',
  },
  limit: {
    path: 'options.limit',
    isLocalized: false,
    basicValidation: 'number',
  },
};

export const PushFormWeblinkFields: PushFieldMetadata = {
  url: {
    path: 'body[0].payload.data.pushPayloadTypeId',
    isLocalized: true,
    basicValidation: 'string',
  },
};

export const PushFormContentFields: PushFieldMetadata = {
  contentId: {
    displayName: 'attached content',
    path: 'body[0].payload.data.contentId',
    isLocalized: false,
    basicValidation: '',
  },
};

export const PushFormActionlinkFields: PushFieldMetadata = {
  actionScheme: {
    displayName: 'action link',
    path: 'body[0].payload.data.actionScheme',
    isLocalized: true,
    basicValidation: 'string',
  },
  actionType: {
    path: 'body[0].payload.data.actionType',
    isLocalized: false,
    basicValidation: '',
  },
};

export const PushFormFields = {
  ...PushFormBodyFields,
  ...PushFormDeliveryFields,
  ...PushFormWeblinkFields,
  ...PushFormContentFields,
  ...PushFormActionlinkFields,
};

/////////////////////////// SCHEDULE CONSTANTS /////////////////////////////////
// initial implementation, pending schedule revamp
export const ScheduleFormAbsoluteFields: PushFieldMetadata = {
  start: {
    path: 'schedule.startScheduler.start',
    isLocalized: false,
    basicValidation: '',
  },
  end: {
    path: 'schedule.endScheduler.start',
    isLocalized: false,
    basicValidation: '',
  },
};

export const ScheduleFormContextFields: PushFieldMetadata = {
  startContexualSchedule: {
    path: 'schedule.startScheduler.contextualRule',
    isLocalized: false,
    basicValidation: '',
  },
  endContexualSchedule: {
    path: 'schedule.endScheduler.contextualRule',
    isLocalized: false,
    basicValidation: '',
  },
};

export const PUSH_LABELS = {
  [PUSH_PAYLOAD_TYPES.BASIC]: 'Basic',
  [PUSH_PAYLOAD_TYPES.CONTENT]: 'Content',
  [PUSH_PAYLOAD_TYPES.WEB_LINK]: 'Web Link',
  [PUSH_PAYLOAD_TYPES.ACTION_LINK]: 'Action Link',
};

export const CONTENT_LABELS = {
  [CONTENT_ACTION_TYPES.INSTANCE]: 'Instance',
  [CONTENT_ACTION_TYPES.BLUEPRINT]: 'Blueprint',
  [CONTENT_ACTION_TYPES.GROUPING]: 'Grouping',
};
