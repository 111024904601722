import React, { useContext } from 'react';

import './LabelPreview.scss';
import LabelFilterContext from '../LabelFilterContext/LabelFilterContext';
import { getIcon } from '@flybits/design-system';
import EmptyStateImage from 'assets/images/label-filter-preview-empty.png';
import ShowMoreImage from 'assets/images/label-filter-preview-more.png';
import LoadingIcon from 'components/Shared/LoadingIcon/LoadingIcon';
import ContentAPI from 'services/api/content.api';
import { useQuery } from '@tanstack/react-query';
import { Content } from 'interface/content/content.interface';
import useSettings from 'hooks/useSetting';
import { getDefaultLanguage } from 'helpers/templated-experience.helper';
import errorImg from 'assets/images/error-img.svg';

const MAIN_CLASS = 'label-preview';
const CLASSES = {
  DISCLAIMER: `${MAIN_CLASS}__disclaimer`,
  EMPTY: `${MAIN_CLASS}__empty`,
  SPINNER: `${MAIN_CLASS}__spinner`,
  TILE: `${MAIN_CLASS}__tile`,
  MORE_TILE: `${MAIN_CLASS}__tile--more`,
  TILE_CONTAINER: `${MAIN_CLASS}__tile__container`,
  TILE_LABEL: `${MAIN_CLASS}__tile__label`,
  TILE_HTML: `${MAIN_CLASS}__tile__html`,
  TILE_INFO: `${MAIN_CLASS}__tile__info`,
  TILE_INFO_TITLE: `${MAIN_CLASS}__tile__info__title`,
};

const MAX_TILES = 6;

const ContentApi = new ContentAPI();

const EmptyState = () => {
  return (
    <div className={CLASSES.EMPTY}>
      <img alt="empty-state-img" src={EmptyStateImage} />
      <h3>No Content Available</h3>
      <p>You currently don’t have any content. Head to the content editor to see content here.</p>
    </div>
  );
};

const LoadingSpinner = () => {
  return (
    <div className={CLASSES.SPINNER}>
      <LoadingIcon />
    </div>
  );
};

const ContentPreviewTile = ({
  content,
  label = '',
  defaultLang = 'en',
}: {
  content: Content;
  label?: string;
  defaultLang?: string;
}) => {
  return (
    <div className={CLASSES.TILE}>
      <img
        src={content?.iconUrl || errorImg}
        onError={(ev) => {
          (ev.target as Element).setAttribute('src', errorImg);
        }}
        className={`${content?.iconUrl && 'contain'}`}
        alt="content icon"
      />
      <div className={CLASSES.TILE_INFO}>
        <span className={CLASSES.TILE_INFO_TITLE}>{content?.localizations?.[defaultLang]?.name}</span>
        {getIcon('infoFilled', {})}
      </div>
      <div className={CLASSES.TILE_LABEL}>{label}</div>
      {content?.renderType === 'html' && <div className={CLASSES.TILE_HTML}>{'</>'}</div>}
    </div>
  );
};

const ShowMoreTile = ({ difference }: { difference: number }) => {
  return (
    <div className={CLASSES.MORE_TILE}>
      <img alt="show more content icon" src={ShowMoreImage} />
      <span>
        +{difference} more related content{difference > 1 ? 's' : ''}
      </span>
    </div>
  );
};

const ContentPreviewTiles = ({
  contents,
  defaultLang,
  label,
}: {
  contents?: Content[];
  defaultLang?: string;
  label?: string;
}) => {
  const contentsToRender = contents?.slice(0, MAX_TILES);
  const showMore = contents && contents.length > MAX_TILES;

  return (
    <div className={CLASSES.TILE_CONTAINER}>
      {contentsToRender?.map((content) => (
        <ContentPreviewTile
          key={`label-preview-content-${content?.id}`}
          content={content}
          defaultLang={defaultLang}
          label={label}
        />
      ))}
      {showMore && <ShowMoreTile difference={contents.length - MAX_TILES} />}
    </div>
  );
};

const LabelFilterPreview = () => {
  const { selectedLabelFilter } = useContext(LabelFilterContext);
  const { languages, isInitialized: isLanguageInit } = useSettings();
  const defaultLang = getDefaultLanguage(languages);
  const {
    data: contents,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey: [
      'active-contents',
      selectedLabelFilter?.label,
      selectedLabelFilter?.limit,
      selectedLabelFilter?.orderBy,
      selectedLabelFilter?.orderAsc,
    ],
    queryFn: () =>
      ContentApi.getActiveInstances({
        label: selectedLabelFilter?.label,
        limit: selectedLabelFilter?.limit,
        orderBy: selectedLabelFilter?.orderBy,
        orderAsc: selectedLabelFilter?.orderAsc,
      }),
    refetchOnWindowFocus: false,
  });

  return (
    <div className={`${MAIN_CLASS}`}>
      <div className={CLASSES.DISCLAIMER}>
        {getIcon('infoFilled', {})}
        <span>Note: This may not be exactly what your end-user will see, but the order will be the same.</span>
      </div>
      {isLoading || isFetching || !isLanguageInit ? (
        <LoadingSpinner />
      ) : !!contents?.data.length ? (
        <ContentPreviewTiles contents={contents?.data} defaultLang={defaultLang} label={selectedLabelFilter?.label} />
      ) : (
        <EmptyState />
      )}
    </div>
  );
};

export default LabelFilterPreview;
