import React, { useState, useCallback, useEffect, useRef, useMemo } from 'react';
// TODO: react-iframe only supports typescript ^3.9.10 right now.
import Iframe from 'react-iframe';
import Session from 'services/session';
import { getTenantId } from 'helpers/templated-experience.helper';
import { genUUID } from 'helpers/common.helper';
import { LoadingIcon } from 'components/Shared/LoadingIcon/LoadingIcon';

import './DynamicContentPreviewIframe.scss';
import useSettings from 'hooks/useSetting';

type TDynamicContentPreviewIframeProps = {
  contentInstanceIds: string[];
  isDetails?: boolean;
  id?: string;
};

const MAIN_CLASS = 'dynamic-content-iframe-preview';
const CLASSES = {
  IFRAME_WRAPPER: `${MAIN_CLASS}__iframe-wrapper`,
  IFRAME: `${MAIN_CLASS}__iframe`,
  LOADING_WRAPPER: `${MAIN_CLASS}__loading-wrapper`,
};

function DynamicContentPreviewIframe({ contentInstanceIds, isDetails = false, id }: TDynamicContentPreviewIframeProps) {
  const session = useMemo(() => new Session(getTenantId()), []);

  // While testing locally with vue-content-manager
  // use http://localhost:3300 (by default)
  const contentManagerUrl = process.env.VUE_CONTENT_MANAGER_URL;
  const iframeUrl = `${contentManagerUrl}/content/preview`;
  const iframeTargetOrigin = useMemo(() => new URL(iframeUrl).origin, [iframeUrl]);
  const [previewId, setPreviewId] = useState(id || genUUID());
  const iframeWrapperRef = useRef<HTMLDivElement>(null);
  const [isIframeLoading, setIframeLoading] = useState(true);
  const { previewTextDirection, previewTextLanguage } = useSettings();

  const iframeMessageListener = useCallback(
    async (event: MessageEvent) => {
      const { source, origin, data: { type } = { type: '' } } = event;

      const iframe = iframeWrapperRef.current?.querySelector(
        `#dynamicContentPreview-${id || previewId}`,
      ) as HTMLIFrameElement;
      const iframeWindow = iframe?.contentWindow;
      const env = process.env.REACT_API_ENV;
      const host = process.env.REACT_APP_API_URL;
      const token = await session.getToken();
      const locale = previewTextLanguage;
      const textDirection = previewTextDirection;

      if (
        !iframe ||
        !iframeWindow ||
        source !== iframeWindow ||
        origin !== iframeTargetOrigin ||
        type !== 'flb.contentpreview.ready'
      ) {
        return;
      }

      if (!env || !host || !token) {
        // eslint-disable-next-line no-console
        console.error('Missing required iframe inputs');
        return;
      }

      setIframeLoading(false);

      const messagePayload = {
        token,
        env,
        host,
        contentInstanceIds,
        isDetails,
        locale,
        textDirection,
      };

      iframeWindow.postMessage(messagePayload, iframeTargetOrigin);
    },
    [
      id,
      previewId,
      session,
      previewTextLanguage,
      previewTextDirection,
      iframeTargetOrigin,
      contentInstanceIds,
      isDetails,
    ],
  );

  useEffect(() => {
    setPreviewId(id || genUUID());
  }, [contentInstanceIds, isDetails, id]);

  useEffect(() => {
    window.addEventListener('message', iframeMessageListener);

    return () => {
      window.removeEventListener('message', iframeMessageListener);
    };
  }, [iframeMessageListener]);

  return (
    <div className={MAIN_CLASS}>
      <div className={CLASSES.IFRAME_WRAPPER} ref={iframeWrapperRef}>
        {isIframeLoading && (
          <div className={CLASSES.LOADING_WRAPPER}>
            <LoadingIcon />
          </div>
        )}
        <Iframe
          className={CLASSES.IFRAME}
          title={`content-preview-${id || previewId}`}
          url={iframeUrl}
          width="100%"
          height="100%"
          id={`dynamicContentPreview-${id || previewId}`}
          position="relative"
          sandbox={['allow-scripts', 'allow-same-origin']}
        />
      </div>
    </div>
  );
}

export default DynamicContentPreviewIframe;
