import React from 'react';
import ImageSubmitted from 'assets/images/merchant-submission.svg';

import './MerchantSubmitted.scss';
import { MerchantFormValues, MerchantsRouteParams } from 'pages/MerchantPortal/merchant-portal.types';
import { useHistory, useParams } from 'react-router-dom';
import { FlightButton } from '@flybits/design-system';

const MAIN_CLASS = 'merchant-submitted';
const CLASSES = {
  IMAGE: `${MAIN_CLASS}__image`,
  TEXT_CONTAINER: `${MAIN_CLASS}__text-container`,
  BUTTONS_CONTAINER: `${MAIN_CLASS}__buttons`,
  BUTTON: `${MAIN_CLASS}__button`,
  BACK_TO_LIST: `${MAIN_CLASS}__back-to-list`,
  BAR: `${MAIN_CLASS}__bar`,
  BAR_WRAPPER: `${MAIN_CLASS}__bar__wrapper`,
};

type MerchantSubmittedProps = {
  isSubmitted: boolean;
  data: MerchantFormValues;
  onReset: () => void;
};

function MerchantSubmitted(props: MerchantSubmittedProps) {
  const { isSubmitted, onReset, data } = props;
  const { id } = data;
  const history = useHistory();
  const { pid: projectId } = useParams<MerchantsRouteParams>();

  const goToMerchantList = () => {
    history.replace(`/project/${projectId}/merchants`);
  };
  const goToCreateMerchant = () => {
    onReset();
  };
  const goToCreateOffer = (merchantId: string) => {
    // populate this value after receive the info from API when it's implemented
    history.replace(`/project/${projectId}/merchants/${merchantId}/create-offer`);
  };
  return (
    <div className={`${MAIN_CLASS}`}>
      <div className={CLASSES.IMAGE}>
        <img src={ImageSubmitted} alt="Merchant information submitted" />
      </div>
      {!isSubmitted ? (
        <div className={CLASSES.BAR_WRAPPER}>
          <div className={CLASSES.BAR}>
            <div></div>
          </div>
        </div>
      ) : (
        <div className={CLASSES.TEXT_CONTAINER}>
          <div className={`${CLASSES.TEXT_CONTAINER}__title`}>Merchant information submitted</div>
          <div className={`${CLASSES.TEXT_CONTAINER}__sub-title`}>
            Your submission has been successfully sent to the Flybits team. The merchant profile will now undergo a
            verification process, which may take up to 3-5 business days.
          </div>
          <div className={`${CLASSES.BUTTONS_CONTAINER}`}>
            <FlightButton label="Add another merchant" theme="secondary" onClick={goToCreateMerchant} />
            <FlightButton label="Add offer to this merchant" onClick={() => goToCreateOffer(id)} />
          </div>
          <div className={`${CLASSES.BACK_TO_LIST}`}>
            <FlightButton label="Go to merchant list" theme="link" onClick={goToMerchantList} />
          </div>
        </div>
      )}
    </div>
  );
}

export default MerchantSubmitted;
