import React, { useContext, useEffect, useState } from 'react';
import {
  FlightButton,
  FlightTextInput,
  FlightNumberInput,
  FlightCheckbox,
  FlightDropdown,
  getIcon,
} from '@flybits/design-system';
import './ModuleInfo.scss';
import '../ZoneModuleSetup/ZoneModuleSetup.scss';
import { validateModuleInfo } from '../zone.validators';
import { MODULE_LAYOUTS, TNavigationButton } from '../types';
import moduleIntroImg from 'assets/images/module-intro.png';
import { ZM_DETAILS, ZM_DETAILS_CLASSES } from '../ZoneModuleSetup/constants';
import PrioritizationContext, {
  initialModuleErrorMessages,
} from 'pages/ZonesV2/PrioritizationContext/PrioritizationContext';

import PredefinedLayoutIcon from './PredefinedLayoutIcons';
import { checkIfPredefinedLayout } from '../zones.helpers';

const MAIN_CLASS = 'zone-attributes';

const CLASS_BODY = `${MAIN_CLASS}__body`;
const CLASS_FIELD = `${CLASS_BODY}__field`;
const CLASS_FIELD_ERROR = `${CLASS_FIELD}__error`;
const CLASS_FIELD_LABEL = `${CLASS_FIELD}__label`;
const CLASS_FIELD_LABEL_BOTTOM = `${CLASS_FIELD_LABEL}--bottom`;

const CLASS_FIELD_LABEL_REQUIRED = `${CLASS_FIELD_LABEL}__required`;
const CLASS_FIELD_TEXT_INPUT = `${CLASS_FIELD}__text-input`;

const MODULE_INFO_CLASS = 'module-info';

const CLASS_FOOTER = `${MODULE_INFO_CLASS}__footer`;
const CLASS_FOOTER_BUTTONS = `${CLASS_FOOTER}__footer-buttons`;
const CLASS_REMOVE_ZONE = `${CLASS_FOOTER}__remove-zone`;
const CLASS_REMOVE_ZONE_TEXT = `${CLASS_REMOVE_ZONE}__text`;
const CLASS_REMOVE_ZONE_BUTTON = `${CLASS_REMOVE_ZONE}__button`;

const CLASS_PREDEFINED_PORTAL = `${MODULE_INFO_CLASS}__predefined__field-container`;
const CLASS_PREDEFINED_FIELD = `${MODULE_INFO_CLASS}__predefined__field`;
const CLASS_PREDEFINED = `${MODULE_INFO_CLASS}__predefined`;
const CLASS_PREDEFINED_TOP = `${CLASS_PREDEFINED}__top`;
const CLASS_PREDEFINED_TOP_LEFT = `${CLASS_PREDEFINED_TOP}__left`;
const CLASS_PREDEFINED_TOP_RIGHT = `${CLASS_PREDEFINED_TOP}__right`;

const CLASS_PREDEFINED_FIELD_NOTE = `${CLASS_PREDEFINED_TOP_LEFT}__predefined-layout-note`;

const CLASS_PREDEFINED_BOTTOM = `${CLASS_PREDEFINED}__bottom`;
const CLASS_PREDEFINED_FIELD_DISCLAIMER_NOTE = `${CLASS_PREDEFINED_BOTTOM}__note`;

export type ModuleInfoErrorMessages = {
  name: string;
  height: string;
  labels: string;
  layout: string;
};

type ModuleInfoProps = {
  navigateToPrev?: TNavigationButton;
  navigateToNext?: TNavigationButton;
  onRemove?: (moduleId: string) => void;
};

function ModuleInfo({ navigateToPrev, navigateToNext, onRemove }: ModuleInfoProps) {
  const {
    selectedModule: module,
    setSelectedModule: setModuleValues,
    moduleErrorMessages: errorMessages,
    setModuleErrorMessages: setErrorMessages,
    isCustomLayout,
    setCustomLayout,
  } = useContext(PrioritizationContext);

  const moduleLayout = (module?.layout as MODULE_LAYOUTS) || MODULE_LAYOUTS.VERTICAL;
  const [isPredefinedLayout, setPredefinedLayout] = useState(checkIfPredefinedLayout(moduleLayout, isCustomLayout));
  const [isPredefinedDropdownOpen, setPredefinedDropdownOpen] = useState(false);

  const handleChangeModuleName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setModuleValues((prev) => ({ ...prev, name: e.target.value }));
  };

  const handleChangeModuleHeight = (e: React.ChangeEvent<HTMLInputElement>) => {
    setModuleValues((prev) => ({ ...prev, height: +e.target.value }));
  };

  const handleRemoveModule = () => {
    if (module?.id && onRemove) onRemove(module.id);
  };

  const PredefinedModuleLayoutDropDown = () => {
    return (
      <div className={`${CLASS_PREDEFINED}`}>
        <div className={`${CLASS_PREDEFINED_TOP}`}>
          <div className={`${CLASS_PREDEFINED_TOP_LEFT}`}>
            <FlightDropdown
              className={`${CLASS_PREDEFINED_PORTAL}`}
              isActive={isPredefinedDropdownOpen}
              isControlledByIsActive
              handleClickOutside={() => {
                setPredefinedDropdownOpen((prev) => !prev);
              }}
              maxHeight="50vh"
              trigger={
                <button
                  className={`${CLASS_PREDEFINED_FIELD}__trigger ${
                    isPredefinedDropdownOpen ? `${CLASS_PREDEFINED_FIELD}__trigger--open` : ''
                  }`}
                  aria-label={`select predefined layout`}
                  onClick={() => setPredefinedDropdownOpen((prev) => !prev)}
                >
                  {/* Display first char in uppercase */}
                  {moduleLayout.charAt(0).toUpperCase() + moduleLayout.slice(1)}
                </button>
              }
            >
              <div className={`${CLASS_PREDEFINED_PORTAL}`}>
                {
                  /* eslint-disable @typescript-eslint/no-unused-vars */
                  Object.entries(MODULE_LAYOUTS).map(([layoutKey, layoutVal]) => {
                    return (
                      <button
                        key={`predefined-layout-${layoutKey}`}
                        className={`${CLASS_PREDEFINED_FIELD}__item`}
                        aria-label={`select ${layoutVal} layout`}
                        onClick={() => {
                          setModuleValues((prev) => ({
                            ...prev,
                            layout: layoutVal,
                          }));
                          setPredefinedDropdownOpen((prev) => !prev);
                        }}
                      >
                        {/* Display first char in uppercase */}
                        {layoutVal.charAt(0).toUpperCase() + layoutVal.slice(1)}
                      </button>
                    );
                  })
                }
              </div>
            </FlightDropdown>
            <div className={`${CLASS_PREDEFINED_FIELD_NOTE}`}>
              Select from a pre-defined layout or create a custom one
            </div>
          </div>
          <div className={`${CLASS_PREDEFINED_TOP_RIGHT}`}>
            <PredefinedLayoutIcon predefinedIconTitle={moduleLayout} />
          </div>
        </div>
        <div className={`${CLASS_PREDEFINED_BOTTOM}`}>
          <div>{getIcon('infoFilled', {})}</div>
          <div className={`${CLASS_PREDEFINED_FIELD_DISCLAIMER_NOTE}`}>
            You have selected a predefined layout and cannot make changes to the code. To modify the layout, please -
            contact the Flybits Team.
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    setErrorMessages(initialModuleErrorMessages);
    const { name, height, layout } = validateModuleInfo(
      // Override layout option validation if users select `Custom` layout
      moduleLayout || isCustomLayout,
      module?.name,
      module?.height,
    );
    setErrorMessages((prev) => ({ ...prev, name, height, layout }));
  }, [setErrorMessages, module, moduleLayout, isCustomLayout]);

  return (
    <div className={ZM_DETAILS}>
      <div className={ZM_DETAILS_CLASSES.FORM}>
        <div className={MAIN_CLASS}>
          <div className={CLASS_BODY}>
            <div className={CLASS_FIELD}>
              <label htmlFor="zone-name" className={CLASS_FIELD_LABEL}>
                Module Name<span className={CLASS_FIELD_LABEL_REQUIRED}>*</span>
              </label>
              <FlightTextInput
                value={module?.name}
                onChange={handleChangeModuleName}
                className={CLASS_FIELD_TEXT_INPUT}
                labelId="zone-name"
                width="100%"
                isAriaRequired
                hasError={!!errorMessages.name}
                errorMessage={errorMessages.name}
              />
            </div>
            <div className={CLASS_FIELD}>
              <label htmlFor="reference-identifer" className={CLASS_FIELD_LABEL}>
                Height (Pixels)<span className={CLASS_FIELD_LABEL_REQUIRED}>*</span>
              </label>
              <FlightNumberInput
                value={module?.height?.toString()}
                onChange={handleChangeModuleHeight}
                className={CLASS_FIELD_TEXT_INPUT}
                width="100%"
                hasError={!!errorMessages.height}
                errorMessage={errorMessages.height}
              />
            </div>

            <div className={`${CLASS_FIELD} ${errorMessages.layout ? CLASS_FIELD_ERROR : ''}`}>
              <label htmlFor="labels" className={CLASS_FIELD_LABEL}>
                Select a layout<span className={CLASS_FIELD_LABEL_REQUIRED}>*</span>
              </label>
              <FlightCheckbox
                label={'Pre-defined layout'}
                checkState={isPredefinedLayout ? 'SELECTED' : 'UNSELECTED'}
                onSelect={() => {
                  setPredefinedLayout(true);
                  setCustomLayout(false);
                  // Set `vertical` as the default pre-defined layout
                  setModuleValues((prev) => ({
                    ...prev,
                    layout: MODULE_LAYOUTS.VERTICAL,
                    // Reset the html and css fields in Module Layout tab once Predefined selected.
                    layoutHTML: '',
                    styleCSS: '',
                  }));
                }}
              />
              {isPredefinedLayout && <PredefinedModuleLayoutDropDown />}
              <FlightCheckbox
                label={'Custom layout'}
                checkState={!isPredefinedLayout ? 'SELECTED' : 'UNSELECTED'}
                onSelect={() => {
                  setCustomLayout(true);
                  setPredefinedLayout(false);
                  // BE only supports `vertical`, `horizontal`, and `expose` module layouts
                  // If users select `custom` layout then set `layout` as `''`
                  setModuleValues((prev) => ({ ...prev, layout: '' }));
                }}
              />
            </div>
          </div>
          <div className={CLASS_FOOTER}>
            <div className={CLASS_FOOTER_BUTTONS}>
              {!!navigateToPrev && (
                <FlightButton
                  ariaLabel={navigateToPrev.label}
                  label={navigateToPrev.label}
                  onClick={navigateToPrev.cta}
                  disabled={navigateToPrev.isDisabled}
                />
              )}
              {!!navigateToNext && (
                <FlightButton
                  ariaLabel={navigateToNext.label}
                  label={navigateToNext.label}
                  onClick={navigateToNext.cta}
                  disabled={navigateToNext.isDisabled}
                />
              )}
            </div>
          </div>
          {onRemove && (
            <div className={CLASS_REMOVE_ZONE}>
              <div className={CLASS_REMOVE_ZONE_TEXT}>
                <label htmlFor="remove-zone" className={CLASS_FIELD_LABEL}>
                  Remove module
                </label>
                <label htmlFor="remove-zone-desc" className={`${CLASS_FIELD_LABEL} ${CLASS_FIELD_LABEL_BOTTOM}`}>
                  Removing this module will also affect contents that are live in the journey
                </label>
              </div>
              <div className={CLASS_REMOVE_ZONE_BUTTON}>
                <FlightButton
                  theme="secondary"
                  label="Remove Module"
                  ariaLabel="Remove Module"
                  onClick={handleRemoveModule}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <div className={ZM_DETAILS_CLASSES.SIDEBAR}>
        <div className={ZM_DETAILS_CLASSES.DIAGRAM_CONTAINER}>
          <img src={moduleIntroImg} alt="module diagram" />
          <div className={`${ZM_DETAILS_CLASSES.DIAGRAM_CONTAINER}__info`}>
            <h2 className={`${ZM_DETAILS_CLASSES.DIAGRAM_CONTAINER}__info__header`}>What is a module?</h2>
            <p className={`${ZM_DETAILS_CLASSES.DIAGRAM_CONTAINER}__info__sub-header`}>
              A Module is a single grouping of Flybits curated content that can take any form in terms of layout. From
              vertical lists, to carousels, to even maps, the possibilities are endless. Flybits has available default
              layouts, but custom layout are possible with HTML/CSS.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModuleInfo;
