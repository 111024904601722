import './MerchantList.scss';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  Merchant,
  MerchantTableData,
  MerchantTableHeader,
  MerchantsRouteParams,
  VERIFICATION_STATUSES,
} from 'pages/MerchantPortal/merchant-portal.types';
import { FlightButton, FlightTable, getIcon } from '@flybits/design-system';
import VerificationStatus from '../VerificationStatus/VerificationStatus';
import useMerchantsMock from 'hooks/useMerchantsMock';
import { sleep } from 'helpers/common.helper';
import { cloneDeep } from 'lodash';
import MerchantSlideout from '../MerchantSlideout/MerchantSlideout';

const MERCHANT_NAME = 'merchant-name';
const MERCHANT_NAME_CLASSES = {
  LOGO: `${MERCHANT_NAME}__logo`,
  INFO: `${MERCHANT_NAME}__info`,
  INFO_NAME: `${MERCHANT_NAME}__info__name`,
  INFO_ID: `${MERCHANT_NAME}__info__id`,
};

interface MerchantNameProps extends React.ComponentProps<'div'> {
  id: string;
  name: string;
  logoUrl: string;
}

const canViewOffer = (status: VERIFICATION_STATUSES, hasOffers: boolean) => {
  return (
    hasOffers ||
    (status !== VERIFICATION_STATUSES.FAILED &&
      status !== VERIFICATION_STATUSES.DELETED &&
      status !== VERIFICATION_STATUSES.PENDING_DELETION)
  );
};

const MerchantName: React.FC<MerchantNameProps> = ({
  id,
  name,
  /* logoUrl */
}) => {
  return (
    <div className={MERCHANT_NAME}>
      <div className={MERCHANT_NAME_CLASSES.LOGO}>
        {/* <img src={logoUrl} alt="merchant logo" /> */}
        {getIcon('merchant', {})}
      </div>
      <div className={MERCHANT_NAME_CLASSES.INFO}>
        <div className={MERCHANT_NAME_CLASSES.INFO_NAME}>{name}</div>
        <div className={MERCHANT_NAME_CLASSES.INFO_ID}>{id.toUpperCase()}</div>
      </div>
    </div>
  );
};

const MERCHANT_LIST = 'merchant-list';
const MERCHANT_LIST_CLASSES = {
  HEADER: `${MERCHANT_LIST}__header`,
  HEADER_TITLE: `${MERCHANT_LIST}__header__title`,
  LIST: `${MERCHANT_LIST}__list`,
  TABLE: `${MERCHANT_LIST}__list__table`,
  OFFERS_BUTTON: `${MERCHANT_LIST}__offers-button`,
};

const MERCHANT_LIST_HEADERS: MerchantTableHeader[] = [
  {
    key: 'data',
    isVisible: false,
  },
  {
    key: 'name',
    name: 'Merchant Name',
    hideTooltip: true,
    isVisible: true,
    isSortable: false,
  },
  {
    key: 'category',
    name: 'Category',
    hideTooltip: true,
    isVisible: true,
    isSortable: true,
  },
  {
    key: 'verificationStatus',
    name: 'Verification Status',
    hideTooltip: true,
    isVisible: true,
    isSortable: true,
  },
  {
    key: 'offers',
    name: 'Merchant Offers',
    hideTooltip: true,
    isVisible: true,
    isSortable: false,
  },
];

// Mock Pagination Data
const mockPaginationData = {
  totalRecords: 30,
  limit: 0,
  offset: 0,
};

const MerchantList: React.FC<React.ComponentProps<'div'>> = () => {
  const history = useHistory();
  const { pid: projectId } = useParams<MerchantsRouteParams>();

  const { merchantsData, areMerchantsLoading }: { merchantsData: Merchant[]; areMerchantsLoading: boolean } =
    useMerchantsMock();

  const [merchants, setMerchants] = useState<Merchant[]>([]);
  const [showMerchantSlideout, setShowMerchantSlideout] = useState(false);
  const [selectedMerchant, setSelectedMerchant] = useState<Merchant>();
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [isMockPageLoading, setMockPageLoading] = useState(false);
  const [sortByKey, setSortKey] = useState<keyof Merchant>('category');
  const [sortOrder, setSortOrder] = useState('asc');

  const handleHeaderSort = (e: { key: keyof Merchant }) => {
    if (e.key !== sortByKey) {
      setSortKey(e.key);
      setSortOrder('desc');
    } else {
      setSortOrder((order) => (order === 'desc' ? 'asc' : 'desc'));
    }
  };

  const tableData = useMemo<MerchantTableData[]>(() => {
    const handleAddAnOffer = (merchantId: string) => {
      history.push(`/project/${projectId}/merchants/${merchantId}/create-offer`);
    };

    return merchants.map((merchant) => ({
      key: merchant.id,
      data: merchant,
      name: <MerchantName id={merchant.id} name={merchant.name} logoUrl={merchant.logoURL} />,
      category: merchant.category,
      verificationStatus: <VerificationStatus type={merchant.verificationStatus} />,
      offers: canViewOffer(merchant.verificationStatus, !!merchant.offers.length) ? (
        merchant.offers.length ? (
          <FlightButton
            theme="link"
            ariaLabel="View offers"
            label="View offers"
            iconLeft="eyeOpenOutline"
            className={MERCHANT_LIST_CLASSES.OFFERS_BUTTON}
          />
        ) : (
          <FlightButton
            theme="link"
            ariaLabel="Add an offer"
            label="Add an offer"
            iconLeft="addCircleOutline"
            className={MERCHANT_LIST_CLASSES.OFFERS_BUTTON}
            onClick={() => {
              handleAddAnOffer(merchant.id);
            }}
          />
        )
      ) : (
        <span>{'N/A'}</span>
      ),
    }));
  }, [merchants, history, projectId]);

  const goToAddMerchant = () => {
    history.push(`/project/${projectId}/register-merchant`);
  };

  const handleSelectMerchant = (merchant: MerchantTableData) => {
    setSelectedMerchant(merchant.data);
    setShowMerchantSlideout(true);
  };

  // Client-side pagination and sorting
  useEffect(() => {
    if (!merchantsData.length) {
      return;
    }

    const merchantsClone = cloneDeep(merchantsData);

    setMerchants(() => {
      const paginatedMerchants = merchantsClone.filter((merchant, idx) => {
        if (currentPage <= 1) {
          return idx >= 0 && idx < perPage;
        } else {
          return idx >= (currentPage - 1) * perPage && idx < (currentPage - 1) * perPage + perPage;
        }
      });

      return paginatedMerchants.sort((a, b) => {
        if (sortOrder === 'asc') {
          if (String(a[sortByKey]) < String(b[sortByKey])) {
            return -1;
          } else if (String(b[sortByKey]) < String(a[sortByKey])) {
            return 1;
          } else {
            return 0;
          }
        } else {
          if (String(a[sortByKey]) > String(b[sortByKey])) {
            return -1;
          } else if (String(b[sortByKey]) > String(a[sortByKey])) {
            return 1;
          } else {
            return 0;
          }
        }
      });
    });
  }, [merchantsData, currentPage, perPage, sortByKey, sortOrder]);

  return (
    <>
      <div className={MERCHANT_LIST}>
        <div className={MERCHANT_LIST_CLASSES.HEADER}>
          <h4 className={MERCHANT_LIST_CLASSES.HEADER_TITLE}>Merchant List</h4>
          <FlightButton ariaLabel="Add new merchant" label="Add new merchant" onClick={goToAddMerchant} />
        </div>
        <div className={MERCHANT_LIST_CLASSES.LIST}>
          <FlightTable
            className={MERCHANT_LIST_CLASSES.TABLE}
            isLoading={areMerchantsLoading || isMockPageLoading}
            tableHeaders={MERCHANT_LIST_HEADERS}
            tableData={tableData}
            allowRowSelect
            handleDataSelect={handleSelectMerchant}
            sortByKey={sortByKey}
            sortOrder={sortOrder}
            handleHeaderSort={handleHeaderSort}
            hasPaginationBeforeTable={false}
            hasPaginationAfterTable={!!merchants.length}
            paginationProps={{
              totalPageNumber: Math.ceil(mockPaginationData.totalRecords / perPage) || 1,
              currentPageNumber: currentPage,
              rowsPerPageOptions: [10],
              currentRowsPerPage: perPage,
              handlePageChange: async (page: number) => {
                // Mock loading effect while changing pages
                setMockPageLoading(true);
                await sleep(500);
                setMockPageLoading(false);

                setCurrentPage(page);
              },
              handleRowsPerPageChange: (rowsPerPage: number) => setPerPage(rowsPerPage),
            }}
          />
        </div>
      </div>
      {/* 
        Would be moved to MerchantPortal route page component if slideouts state would be managed
        by the merchant global state later in the future
      */}
      <MerchantSlideout
        show={showMerchantSlideout}
        setShow={setShowMerchantSlideout}
        merchant={(selectedMerchant ?? {}) as Merchant}
      />
    </>
  );
};

export default MerchantList;
