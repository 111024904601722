import AnalyticsOverview from './Analytics/Overview/AnalyticsOverview';
import ApprovalRequest from './ApprovalRequest/ApprovalRequest';
// Settings
import AuditHistory from './Settings/Audit/Audit';
import CustomModuleAnalytics from './Analytics/CustomModuleAnalytics/CustomModuleAnalytics';
import DeveloperHubIndex from './DeveloperHub/DeveloperHubIndex';
import DynamicContentManager from './DynamicContentManager/DynamicContentManager';
// Experience Canvas
import ExperienceOverview from './ExperienceCanvas/Overview/ExperienceOverview';
import FlowBuilder from './FlowBuilder/FlowBuilder';
import HomeIndex from './Home/HomeIndex';
import LocationManagement from './LocationManagement/Overview/LocationManagement';
import NoAccess from './NoAccess/NoAccess';
import NotFoundIndex from './NotFound/NotFoundIndex';
import RedirectIndex from './Redirect/RedirectIndex';
import TemplatedExperienceIndex from './TemplatedExperience/Index/TemplatedExperienceIndex';
import TemplatesLibrary from './TemplatedExperience/TemplatesLibrary/TemplatesLibrary';
import ThemeHub from './ThemeHub/ThemeHub';
import TouchpointEditor from './ExperienceCanvas/TouchpointEditor/TouchpointEditor';
import Zone from './ZonesV2/Zone';
import Zones from './Zones/Zones';
import ZonesV2 from './ZonesV2/Zones';
import ZonesOnboarding from './ZonesV2/ZonesOnboarding';
import MerchantPortal from './MerchantPortal/MerchantPortal';
import MerchantOffers from './MerchantPortal/MerchantOffers';
import CreateMerchantOffer from './MerchantPortal/CreateMerchantOffer/CreateMerchantOffer';
import RegisterMerchant from './MerchantPortal/RegisterMerchant/RegisterMerchant';

export default {
  DeveloperHubIndex,
  ThemeHub,

  HomeIndex,
  RedirectIndex,
  TemplatedExperienceIndex,
  TemplatesLibrary,
  CustomModuleAnalytics,
  AnalyticsOverview,
  LocationManagement,
  DynamicContentManager,
  FlowBuilder,
  ApprovalRequest,

  ExperienceOverview,
  TouchpointEditor,
  AuditHistory,
  Zone,
  Zones,
  ZonesV2,
  ZonesOnboarding,

  MerchantPortal,
  MerchantOffers,
  RegisterMerchant,
  CreateMerchantOffer,

  NoAccess,
  NotFoundIndex,
};
