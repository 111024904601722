export const MERCHANT_EMPTY_PAGE_TEXTS = {
  HEADING: 'Get Started with Local Merchants',
  TITLE: 'Currently, there are no local merchants added yet.',
  DESCRIPTION: 'Add a local merchant now to provide your customers with special offers and cashback.',
};

export const MERCHANT_EMPTY_PAGE_BUTTON_LABELS = {
  ADD_MERCHANT: 'Add a local merchant',
};
export const US_STATES = [
  // {
  //   name: 'Alabama',
  //   code: 'AL',
  // },
  // {
  //   name: 'Alaska',
  //   code: 'AK',
  // },
  // {
  //   name: 'Arizona',
  //   code: 'AZ',
  // },
  // {
  //   name: 'Arkansas',
  //   code: 'AR',
  // },
  // {
  //   name: 'California',
  //   code: 'CA',
  // },
  // {
  //   name: 'Colorado',
  //   code: 'CO',
  // },
  // {
  //   name: 'Connecticut',
  //   code: 'CT',
  // },
  // {
  //   name: 'Delaware',
  //   code: 'DE',
  // },
  // {
  //   name: 'Florida',
  //   code: 'FL',
  // },
  // {
  //   name: 'Georgia',
  //   code: 'GA',
  // },
  // {
  //   name: 'Hawaii',
  //   code: 'HI',
  // },
  // {
  //   name: 'Idaho',
  //   code: 'ID',
  // },
  // {
  //   name: 'Illinois',
  //   code: 'IL',
  // },
  // {
  //   name: 'Indiana',
  //   code: 'IN',
  // },
  // {
  //   name: 'Iowa',
  //   code: 'IA',
  // },
  // {
  //   name: 'Kansas',
  //   code: 'KS',
  // },
  // {
  //   name: 'Kentucky',
  //   code: 'KY',
  // },
  // {
  //   name: 'Louisiana',
  //   code: 'LA',
  // },
  // {
  //   name: 'Maine',
  //   code: 'ME',
  // },
  // {
  //   name: 'Maryland',
  //   code: 'MD',
  // },
  // {
  //   name: 'Massachusetts',
  //   code: 'MA',
  // },
  // {
  //   name: 'Michigan',
  //   code: 'MI',
  // },
  // {
  //   name: 'Minnesota',
  //   code: 'MN',
  // },
  // {
  //   name: 'Mississippi',
  //   code: 'MS',
  // },
  // {
  //   name: 'Missouri',
  //   code: 'MO',
  // },
  // {
  //   name: 'Montana',
  //   code: 'MT',
  // },
  // {
  //   name: 'Nebraska',
  //   code: 'NE',
  // },
  // {
  //   name: 'Nevada',
  //   code: 'NV',
  // },
  // {
  //   name: 'New Hampshire',
  //   code: 'NH',
  // },
  // {
  //   name: 'New Jersey',
  //   code: 'NJ',
  // },
  // {
  //   name: 'New Mexico',
  //   code: 'NM',
  // },
  // {
  //   name: 'New York',
  //   code: 'NY',
  // },
  // {
  //   name: 'North Carolina',
  //   code: 'NC',
  // },
  // {
  //   name: 'North Dakota',
  //   code: 'ND',
  // },
  // {
  //   name: 'Ohio',
  //   code: 'OH',
  // },
  // {
  //   name: 'Oklahoma',
  //   code: 'OK',
  // },
  // {
  //   name: 'Oregon',
  //   code: 'OR',
  // },
  {
    name: 'Pennsylvania',
    code: 'PA',
  },
  // {
  //   name: 'Rhode Island',
  //   code: 'RI',
  // },
  // {
  //   name: 'South Carolina',
  //   code: 'SC',
  // },
  // {
  //   name: 'South Dakota',
  //   code: 'SD',
  // },
  // {
  //   name: 'Tennessee',
  //   code: 'TN',
  // },
  // {
  //   name: 'Texas',
  //   code: 'TX',
  // },
  // {
  //   name: 'Utah',
  //   code: 'UT',
  // },
  // {
  //   name: 'Vermont',
  //   code: 'VT',
  // },
  // {
  //   name: 'Virginia',
  //   code: 'VA',
  // },
  // {
  //   name: 'Washington',
  //   code: 'WA',
  // },
  // {
  //   name: 'West Virginia',
  //   code: 'WV',
  // },
  // {
  //   name: 'Wisconsin',
  //   code: 'WI',
  // },
  // {
  //   name: 'Wyoming',
  //   code: 'WY',
  // },
];

// This code will match a US or Canadian phone number, and will also make sure that it is a valid area code and exchange
export const phoneRegex = /\(?\d+\)?[-.\s]?\d+[-.\s]?\d+/;
export const addressRegex = /[\w',-.\s]+/;
export const zipRegex = /^\d{5}(-\d{4})?$/;
// https://emailregex.com/
export const emailRegex =
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
