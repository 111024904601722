export type MerchantsRouteParams = Partial<{
  pid: string;
  merchantid: string;
  offerid: string;
}>;

export type MerchantTableHeader =
  | {
      key: keyof Merchant;
      name: string;
      hideTooltip: boolean;
      isVisible: boolean;
      isSortable: boolean;
    }
  | {
      key: 'data';
      isVisible: boolean;
    };

export type MerchantTableData = {
  key: string;
  data: Merchant;
  name: React.JSX.Element;
  category: string | undefined;
  verificationStatus: React.JSX.Element;
  offers: React.JSX.Element;
};

export enum VERIFICATION_STATUSES {
  PENDING = 'pending',
  VERIFIED = 'verified',
  FAILED = 'failed',
  DELETED = 'deleted',
  PENDING_DELETION = 'pending-deletion',
}

enum OFFER_STATUSES {
  INACTIVE = 'inactive',
  DRAFT = 'draft',
  SCHEDULED = 'scheduled',
  EXPIRED = 'expired',
  CANCELLED = 'cancelled',
}

export type Offer = {
  id: string;
  merchantId: string;
  tenantId: string;
  journeyId: string;
  title: string;
  description: string;
  status: OFFER_STATUSES;
  verificationStatus: VERIFICATION_STATUSES;
  spendThreshold: number;
  earnCap: number;
  notes: string;
  desiredStartDate: EpochTimeStamp;
  desiredEndDate: EpochTimeStamp;
  targetBudget: number;
  budgetConsumed: number;
  budgetNotes: string;
  createdAt: EpochTimeStamp;
};

export type MerchantFields = {
  id: string;
  name: string;
  externalId: string;
  categoryId: string;
  logoURL: string;
  phoneNumber: string;
  addressLine: string;
  addressCity: string;
  addressState: string;
  addressZipCode: string;
  contactName: string;
  contactPhoneNumber: string;
  contactEmail: string;
  verificationStatus: VERIFICATION_STATUSES;
  notes: string;
  createdAt: EpochTimeStamp;
  updatedAt: EpochTimeStamp;
  createdBy: string;
  offers: Offer[];
};

export type MerchantUIFields = Partial<{
  // BE is planning to consider `category` as a separate entity
  // Adding this here to make the merchant list rendering simpler
  category: string;
  address: string;
  logo: string;
}>;

export type Merchant = MerchantFields & MerchantUIFields;

export type MerchantCreationPayload = Omit<Merchant, 'id'>;
export type OfferCreationPayload = Omit<Offer, 'id'>;
export type MerchantAndOfferCreationPayload = Omit<MerchantCreationPayload, 'offers'> & {
  offers: OfferCreationPayload[];
};

export interface MerchantFormValues {
  name: string;
  id: string;
  category: string | null;
  logo: string;
  phone: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  contact: {
    name: string;
    phone: string;
    email: string;
  };
}
export interface OfferFormValues {
  merchantName?: string;
  type: string | null;
  redemptionLimit: string | null;
  percentage: number;
  spendThreshold: number;
  earnCap: number;
  notes: string;
  title: string;
  description: string;
}
export interface OfferScheduleFormValues {
  startDate: number;
  endDate: number;
  targetBudget: number;
  budgetNote: string;
}
export interface OfferAllFormValues {
  offer: OfferFormValues;
  schedule: OfferScheduleFormValues;
}
