import React, { useContext, useMemo, useState } from 'react';

import { ReactComponent as ContentWithLabelIcon } from 'assets/icons/content-with-label.svg';
import { ReactComponent as NoLabelIcon } from 'assets/icons/icon-label.svg';

import { toOrderBy, ORDER_BY_LIST, ORDER_DIRECTION_LIST, toOrderDirection } from '../zones.constants';

import './LabelConfiguration.scss';
import { FlightNumberInput, FlightSelect, FlightToggleSwitch } from '@flybits/design-system';
import LabelFilterContext from './LabelFilterContext/LabelFilterContext';

const LABEL_CONFIGURATION = 'label-configuration';
const LABEL_BODY = `${LABEL_CONFIGURATION}__body`;

const SortLabel = () => {
  const { selectedLabelFilter, setSelectedLabelFilter } = useContext(LabelFilterContext);

  const selectedItem = useMemo(() => toOrderBy(selectedLabelFilter?.orderBy ?? ''), [selectedLabelFilter]);

  return (
    <div>
      <FlightSelect
        dropdownDirection="bottom"
        dropdownMaxHeight="232px"
        handleOptionClick={(item: any) => {
          setSelectedLabelFilter((prev) => {
            return prev ? { ...prev, orderBy: item.key } : null;
          });
        }}
        label=""
        options={ORDER_BY_LIST}
        selected={selectedItem}
      />
    </div>
  );
};

const SortDirection = () => {
  const { selectedLabelFilter, setSelectedLabelFilter } = useContext(LabelFilterContext);
  const selectedItem = useMemo(() => toOrderDirection(selectedLabelFilter?.orderAsc ?? false), [selectedLabelFilter]);

  return (
    <div>
      <FlightSelect
        dropdownDirection="bottom"
        dropdownMaxHeight="232px"
        handleOptionClick={() => {
          setSelectedLabelFilter((prev) => {
            return prev ? { ...prev, orderAsc: !selectedLabelFilter?.orderAsc ?? false } : null;
          });
        }}
        label=""
        options={ORDER_DIRECTION_LIST}
        selected={selectedItem}
      />
    </div>
  );
};

const LABEL_BODY_SORT_FIELDS = `${LABEL_BODY}__sort-fields`;
const LABEL_BODY_SORT_FIELDS_LABEL_TEXT = `${LABEL_BODY_SORT_FIELDS}__label-text`;
const LABEL_BODY_SORT_FIELDS_LABEL_TEXT_LABEL_NAME = `${LABEL_BODY_SORT_FIELDS_LABEL_TEXT}__label-name`;
const LABEL_BODY_SORT_FIELDS_LABEL_TEXT_MODULE_NAME = `${LABEL_BODY_SORT_FIELDS_LABEL_TEXT}__module-name`;
const LABEL_BODY_SORT_FIELDS_SORT_SELECTORS = `${LABEL_BODY_SORT_FIELDS}__sort-selectors`;
const SortSelection = () => {
  const { selectedLabelFilter, moduleName } = useContext(LabelFilterContext);

  return (
    <div className={`${LABEL_BODY_SORT_FIELDS}`}>
      <div className={`${LABEL_BODY_SORT_FIELDS_LABEL_TEXT}`}>
        <span>All content labelled as</span>
        <span className={`${LABEL_BODY_SORT_FIELDS_LABEL_TEXT_LABEL_NAME}`}> {selectedLabelFilter?.label} </span>
        <span>displayed in </span>
        <span className={`${LABEL_BODY_SORT_FIELDS_LABEL_TEXT_MODULE_NAME}`}>{moduleName}</span>
        <span> will be sorted by </span>
      </div>
      <div className={`${LABEL_BODY_SORT_FIELDS_SORT_SELECTORS}`}>
        <SortLabel /> from <SortDirection />
      </div>
    </div>
  );
};

const LABEL_HEADER = `${LABEL_CONFIGURATION}__header`;
const LABEL_HEADER_LABEL = `${LABEL_HEADER}__label`;
const SortHeader = () => {
  return (
    <div className={`${LABEL_HEADER}`}>
      <ContentWithLabelIcon />
      <div className={`${LABEL_HEADER_LABEL}`}>Content within label</div>
    </div>
  );
};

const LABEL_EMPTY = `${LABEL_CONFIGURATION}__empty`;
const LABEL_EMPTY_LABEL = `${LABEL_EMPTY}__label`;
const NoLabelSelected = () => {
  return (
    <div className={`${LABEL_EMPTY}`}>
      <NoLabelIcon />
      <h2>Select a label</h2>
      <p className={`${LABEL_EMPTY_LABEL}`}>
        When you have added and selected a label in the left panel you will be able to manage the content within it
        here.
      </p>
    </div>
  );
};

const LABEL_BODY_LIMIT = `${LABEL_BODY}__limit`;
const LABEL_BODY_LIMIT_DISCLAIMER = `${LABEL_BODY_LIMIT}__disclaimer`;
const LABEL_BODY_LIMIT_DISCLAIMER_TEXT = `${LABEL_BODY_LIMIT_DISCLAIMER}__text`;
const LABEL_BODY_LIMIT_DISCLAIMER_TEXT_LABEL = `${LABEL_BODY_LIMIT_DISCLAIMER_TEXT}__label`;
const LABEL_BODY_LIMIT_DISCLAIMER_TEXT_SUBLABEL = `${LABEL_BODY_LIMIT_DISCLAIMER_TEXT}__sublabel`;
const SetLimit = () => {
  const [isFlightNumberInputDisabled, setFlightNumbetInputDisabled] = useState(true);
  const { selectedLabelFilter, setSelectedLabelFilter } = useContext(LabelFilterContext);

  const selectedItem = useMemo(() => selectedLabelFilter?.limit ?? 0, [selectedLabelFilter]);

  return (
    <div className={`${LABEL_BODY_LIMIT}`}>
      <div className={`${LABEL_BODY_LIMIT_DISCLAIMER}`}>
        <div className={`${LABEL_BODY_LIMIT_DISCLAIMER_TEXT}`}>
          <div className={`${LABEL_BODY_LIMIT_DISCLAIMER_TEXT_LABEL}`}>Set Limit</div>
          <div className={`${LABEL_BODY_LIMIT_DISCLAIMER_TEXT_SUBLABEL}`}>
            If you don’t set a limit its possible for the end user to see all contents within this label
          </div>
        </div>
        <FlightToggleSwitch
          onChange={() => setFlightNumbetInputDisabled((prev) => !prev)}
          checked={isFlightNumberInputDisabled}
        />
      </div>
      <FlightNumberInput
        minValue={1}
        value={selectedItem}
        onChange={(value: any) => {
          setSelectedLabelFilter((prev) => {
            return prev ? { ...prev, limit: Number(value.target.value) } : null;
          });
        }}
        disabled={!isFlightNumberInputDisabled}
      />
    </div>
  );
};

const LabelConfiguration = () => {
  const { selectedLabelFilter } = useContext(LabelFilterContext);
  return (
    <div className={`${LABEL_CONFIGURATION}`}>
      <SortHeader />
      <hr />
      <div className={`${LABEL_BODY}`}>
        {selectedLabelFilter ? (
          <>
            <SortSelection />
            <SetLimit />
          </>
        ) : (
          <NoLabelSelected />
        )}
      </div>
    </div>
  );
};

export default LabelConfiguration;
